/*   BOOTSTRAP IMPORTS  */
/* https://getbootstrap.com/docs/5.2/customize/sass/#variable-defaults */

// SCSS Colors

// 9.2 Branding updates

// New Colors
$violet: #695fe6;
$lavendar: #d7d2ff;
$green: #5ad28c;
$light-blue: #a5ebff;
$yellow: #ffdc8c;
$beige: #f7f4f2;
$dark-grey2: #555555;
$dot-indicator-grey: #bfbfbf;
$navy: #071464;

// Updated Colors
$purple: #551e5f;
$primary: #051464 !default;
$grey: #d5d5d5;

$ia-blue: #2f5db2;
$ia-blue-link: #003399;
$secondary: $purple !default;
$ia-dkblue: #051464;
$light-grey: #ececec;
$dark-grey: #414042;
$text-grey: #545454;
$visa-blue: #1a225e;
$ia-blue-button: #0067a9;
$icon-grey: #6c757d;
$gold: #ceb888;
$white: #ffffff;
$black: #000000;
//Banner Colors
$banner-grey: #d4d4d0;
$banner-purple: #b6b8dc;
$banner-gold: $gold;
$banner-dkgrey: #d7d7d4;

// Custom Variable Definitions

$enable-negative-margins: true !default;

$body-bg: $beige !default;
$body-color: $black !default;

$font-family-base: 'neueplak' !default;
$font-weight-base: 500 !default;
$responsive-font-size: true !default;

$nav-link-font-weight: 600 !default;
$nav-link-color: $black !default;
$nav-link-hover-color: $purple !default;
$nav-link-padding-y: 0rem !default;
// .nav-link-hover-decoration defined below

$btn-close-color: $primary !default;
$btn-close-opacity: 1 !default;
$btn-close-hover-opacity: 0.66 !default;
$btn-close-focus-opacity: 0.66 !default;
$btn-padding-x: 3rem !default;
$btn-padding-y: 0.5rem !default;
$btn-font-family: 'neueplak' !default;
$btn-font-weight: 600 !default;
$btn-focus-width: 4px !default;
$btn-hover-bg-shade-amount: 0% !default;
$btn-hover-bg-tint-amount: 0% !default;
$link-decoration: underline !default;
$link-hover-decoration: none !default;
$link-color: $primary;
$link-hover-color: $purple;

$card-border-radius: 0px !default;

$badge-padding-y: 0.8rem !default;
$badge-padding-x: 1.5rem !default;

$accordion-button-padding-y: 0.6rem !default;
$accordion-bg: $white !default;
$accordion-button-bg: $white !default;
$accordion-button-active-bg: $white !default;
$accordion-border-radius: 0px !default;
$accordion-button-color: $black !default;
$accordion-color: $black !default;
$accordion-button-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 18'><path d='M9,13.9746a.9494.9494,0,0,1-.6709-.278L.278,5.6451A.9487.9487,0,1,1,1.62,4.3034L9,11.684,16.38,4.3034A.9487.9487,0,1,1,17.722,5.6451L9.6705,13.6966A.9492.9492,0,0,1,9,13.9746Z'/></svg>") !default;
$accordion-button-active-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 18'><path d='M9,13.9746a.9494.9494,0,0,1-.6709-.278L.278,5.6451A.9487.9487,0,1,1,1.62,4.3034L9,11.684,16.38,4.3034A.9487.9487,0,1,1,17.722,5.6451L9.6705,13.6966A.9492.9492,0,0,1,9,13.9746Z'/></svg>") !default;
// $accordion-button-focus-border-color: $purple !default;

$input-btn-focus-box-shadow: 0 0 1px 0.2rem rgba(85, 30, 95, 0.75) !default;

$dropdown-border-radius: 0px !default;
$dropdown-border-width: 0px !default;

$form-check-input-border-radius: 0px !default;
$form-check-input-bg: $white !default;
$form-check-input-border: 2px solid $black !default;
$form-check-input-width: 1.6em !default;
$form-check-margin-bottom: 0.5rem !default;
$form-check-input-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 150 150'><polygon points='112.927 26.218 75.169 64.924 37.412 26.218 27.396 36.485 65.154 75.19 27.393 113.9 37.412 124.161 75.169 85.457 112.927 124.161 122.943 113.9 85.184 75.191 122.943 36.485 112.927 26.218' fill='#051464'/></svg>") !default;
$form-check-input-checked-border-color: $black !default;
$form-check-label-color: $black !default;

$breadcrumb-item-padding-x: 0.5rem !default;
$breadcrumb-active-color: $dark-grey2 !default;
$breadcrumb-font-size: 0.8rem !default;

$popover-bg: $primary !default;

$carousel-control-color: $primary !default;
$carousel-control-width: 5% !default;
$carousel-control-hover-opacity: 0.85 !default;
// carousel control background customized below

$carousel-indicator-width: 10px !default;
$carousel-indicator-height: 10px !default;
$carousel-indicator-opacity: 1 !default;
$carousel-indicator-active-bg: $dot-indicator-grey !default;

$table-striped-bg: rgba(255, 255, 255, 0.8) !default;
$table-striped-order: even !default;
$table-border-width: 0 !default;
$table-cell-padding-y-sm: 1.2rem !default;
$table-cell-padding-x-sm: 1rem !default;

// Configuration
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

// Bootstrap Color Mapping
$custom-colors: (
  'violet': $violet,
  'lavendar': $lavendar,
  'green': $green,
  'light-blue': $light-blue,
  'beige': $beige,
  'dark-grey2': $dark-grey2,
  'yellow': $yellow,
  'dot-indicator-grey': $dot-indicator-grey,
  'purple': $purple,
  'primary': $ia-dkblue,
  'ia-blue': $ia-blue,
  'ia-blue-link': $ia-blue-link,
  'ia-dkblue': $ia-dkblue,
  'light-grey': $light-grey,
  'dark-grey': $dark-grey,
  'text-grey': $text-grey,
  'visa-blue': $visa-blue,
  'ia-blue-button': $ia-blue-button,
  'icon-grey': $icon-grey,
  'grey': $grey,
  'gold': $gold,
  'white': $white,
  'black': $black,
  //Banner Colors
  'banner-visa-blue': $visa-blue,
  'banner-grey': $banner-grey,
  'banner-purple': $banner-purple,
  'banner-gold': $gold,
  'banner-dkgrey': $banner-dkgrey
);

// Bootstrap 5.1 does not allow color-integration (bg-color, text-color)
// utility maps colors to match classes
@each $color, $value in $custom-colors {
  .bg-#{$color} {
    background-color: $value !important;
  }
  .text-#{$color} {
    color: $value !important;
  }
}
$theme-colors: map-merge($theme-colors, $custom-colors);

@import '~bootstrap/scss/utilities';
// Layout & components
@import '~bootstrap/scss/root';
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/type';
@import '~bootstrap/scss/images';
@import '~bootstrap/scss/containers';
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/tables';
@import '~bootstrap/scss/forms';
@import '~bootstrap/scss/buttons';
@import '~bootstrap/scss/transitions';
@import '~bootstrap/scss/dropdown';
@import '~bootstrap/scss/button-group';
@import '~bootstrap/scss/nav';
@import '~bootstrap/scss/navbar';
@import '~bootstrap/scss/card';
@import '~bootstrap/scss/accordion';
@import '~bootstrap/scss/breadcrumb';
@import '~bootstrap/scss/pagination';
@import '~bootstrap/scss/badge';
@import '~bootstrap/scss/alert';
// @import '~bootstrap/scss/progress';
@import '~bootstrap/scss/list-group';
@import '~bootstrap/scss/close';
// @import '~bootstrap/scss/toasts';
@import '~bootstrap/scss/modal';
@import '~bootstrap/scss/tooltip';
@import '~bootstrap/scss/popover';
@import '~bootstrap/scss/carousel';
// @import '~bootstrap/scss/spinners';
// @import '~bootstrap/scss/offcanvas';

// Helpers
@import '~bootstrap/scss/helpers';

// Utilities
@import '~bootstrap/scss/utilities/api';

/*****************************************/

@font-face {
  font-family: 'neueplak';
  src: url('../src/assets/fonts/neueplak-regular-webfont.woff2') format('woff2'),
    url('../src/assets/fonts/neueplak-regular-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'neue_plak_textsemibold';
  src: url('../src/assets/fonts/neueplaktext-semibold-webfont.woff2') format('woff2'),
    url('../src/assets/fonts/neueplaktext-semibold-webfont.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'neueplak';
  src: url('../src/assets/fonts/neueplak-bold-webfont.woff2') format('woff2'),
    url('../src/assets/fonts/neueplak-bold-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'neueplak';
  src: url('../src/assets/fonts/neueplak-extrablack-webfont.woff2') format('woff2'),
    url('../src/assets/fonts/neueplak-extrablack-webfont.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

.font-neue-plak {
  font-family: 'neueplak';
  font-weight: 400;
}

.font-neue-plak-semi {
  font-family: 'neueplak';
  font-weight: 400;
}
.font-neue-plak-med {
  font-family: 'neueplak';
  font-weight: 700;
}

.font-neue-plak-bold {
  font-family: 'neueplak';
  font-weight: 900;
}

.text-truncate {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: unset;
}

.line-height-100 {
  line-height: 100%;
}
.line-height-120 {
  line-height: 120%;
}
.line-height-150 {
  line-height: 150%;
}
.line-height-200 {
  line-height: 200%;
}

.nav-link-hover-decoration {
  transition: 0.2s linear;
  border-bottom: 1px solid transparent;
  &:hover,
  &:focus {
    border-bottom: 1px solid $purple;
  }
}

.breadcrumb-item {
  a {
    color: $black;
  }
  &.active {
    color: $dark-grey2;
    font-weight: 400 !important;
  }
}

// Added for CTA in Contact Modal where this is it's only class
// .btn {
//   background-color: $primary;
//   color: $white;
//   &:hover,
//   &:focus {
//     background-color: $purple;
//     color: $white;
//   }
// }

.btn-primary,
.btn-secondary {
  border: 0px;
  &:hover,
  &:focus {
    background-color: $purple;
    box-shadow: 0 0 0 4px rgba(85, 30, 95, 0.75);
  }
}

// btn-beige introduced in R9.2, only used in navigation header
.btn-beige {
  border: 0px;
  &:hover,
  &:focus {
    background-color: $purple;
    box-shadow: 0 0 0 4px rgba(85, 30, 95, 0.75);
    color: $white;
  }
  &:focus-visible {
    outline: auto 1px rgba(85, 30, 95, 0.75) !important;
  }
}

button[class*='outline'],
a[class*='outline'] {
  &:hover,
  &:focus {
    color: $white;
    background-color: $purple;
    box-shadow: 0 0 0 4px #5e286180;
    border-color: rgba(85, 30, 95, 0.75);
  }
  &:focus-visible {
    outline: auto 1px rgba(85, 30, 95, 0.75) !important;
  }
}
button,
a,
.hamburger-trigger {
  &:hover,
  &:focus {
    border-color: rgba(85, 30, 95, 0.75) !important;
    outline-color: rgba(85, 30, 95, 0.75) !important;
    outline-width: 4px !important;
  }
  &:focus-visible {
    outline: auto 1px rgba(85, 30, 95, 0.75) !important;
  }
}

// on hover of badges, make sure copy and X animate transition equivalently
.btn-close {
  transition: 0.2s linear;
}

.hover-ia-blue-link {
  transition: 0.2s linear;
  &:hover,
  &:focus {
    background: $purple !important;
    color: $white !important;
  }
}

.btn-link {
  &:hover,
  &:focus {
    color: $purple !important;
  }
}
.pill-outline {
  border: 1px solid black !important;
}

#hero-vid {
  background: rgba(0, 0, 0, 0.4) !important;
  @media (max-width: 992px) {
    background: white !important;
  }
}

#vid-btn.btn-link:hover,
#vid-btn.btn-link:focus {
  outline: 0;
  box-shadow: 0 0 1px 0.2rem white !important;
}

// .hover-no-underline {
//   transition: 0.2s linear;
//   text-decoration: underline;
//   &:hover,
//   &:focus {
//     text-decoration: none;
//   }
// }

// .hover-underline {
//   &:hover,
//   &:focus {
//     text-decoration: underline !important;
//   }
// }

// .hover-no-border {
//   &:hover,
//   &:focus {
//     border-bottom: 0px !important;
//   }
// }

.hover-text-purple {
  transition: 0.2s linear;
  &:hover,
  &:focus {
    color: $purple !important;
  }
}

.hover-fade {
  &:hover,
  &:focus {
    opacity: 0.8;
  }
}

// .hover-purple-bg {
//   transition: 0.2s linear;
//   &:hover,
//   &:focus {
//     background: $purple;
//   }
// }

.carousel-indicators {
  & button:focus {
    border-color: $navy !important;
    outline-color: $navy !important;
  }
  bottom: -4.5rem;
  [data-bs-target] {
    border-radius: 100px;
    border: 3px solid $navy;
    margin: 0 5px;
    background: transparent;
    &:focus {
      outline: 3px solid #000000 !important;
      outline-offset: 3px !important;
      border: 3px solid $navy;
    }
    &:focus-visible {
      outline: 3px solid #000000 !important;
      outline-offset: 3px !important;
      border: 3px solid $navy;
    }

    &.active {
      background: $navy;
      &:focus {
        outline: 3px solid #000000 !important;
        outline-offset: 3px !important;
        border: 3px solid $navy;
      }
      &:focus-visible {
        background: $navy;
        border: 3px solid $navy;
        outline: 3px solid #000000 !important;
        outline-offset: 3px !important;
      }
    }
  }
}
.carousel-control-prev,
.carousel-control-next {
  background: $light-grey;
  opacity: 0.75;
  &:focus,
  &:focus-visible {
    border: 2px solid $primary;
  }
}
.form-check-input:checked {
  background-color: unset;
}

// animate rotation of arrow when drawer is open
// .accordion {
//   &:not(.show) {
//     .accordion-button {
//       &:after {
//         transform: rotate(0deg);
//         transition: all 0.2s ease-in-out;
//       }
//     }
//   }
//   &.show {
//     .accordion-button {
//       &:after {
//         transform: rotate(180deg);
//         transition: all 0.2s ease-in-out;
//       }
//     }
//   }
// }

// animate rotation of arrow in mobile accordion
// .accordion-button {
//   transition: all 0.2s ease-in-out;
//   &.collapsed {
//     &:after {
//       transform: rotate(0deg);
//     }
//   }
//   &:not(.collapsed) {
//     &:after {
//       transform: rotate(180deg) !important;
//     }
//   }
// }
.accordion-button:not(.collapsed) {
  color: $black;
  background-color: #ffffff;
}
.accordion-button {
  transition: all 0.2s ease-in-out;
  &[aria-expanded='true'] {
    &:after {
      transform: rotate(180deg);
    }
  }
  &[aria-expanded='false'] {
    &:after {
      transform: rotate(0deg);
    }
  }
  &:hover,
  &:focus {
    box-shadow: 0 0 0 4px rgba(85, 30, 95, 0.75);
  }
}
.popover-arrow-custom {
  &:before {
    right: 65%;
    top: 1px;
    position: absolute;
    content: '';
    border-style: solid;
    border-width: 0 7px 7px 7px;
    border-color: transparent transparent $primary transparent;
  }
}

// custom breakpoint-specific color changes
@include media-breakpoint-up(lg) {
  .bg-lg-transparent {
    background: transparent !important;
  }
  .text-lg-white {
    color: $white !important;
  }
}

// .hero {
//   @include media-breakpoint-up(lg) {
//     background-image: url(../src/assets/hero-image.jpg);
//     background-size: cover;
//     //temp
//     color: white;
//   }
// }

// Event Tile
// a.card-event {
//   &:hover {
//     text-decoration: none;
//     color: $ia-dkblue;

//     .card-event-image {
//       img {
//         transform: scale(1.1);
//       }

//       .card-event-sale-status {
//         text-decoration: underline;
//       }
//     }
//   }

//   .card-event-image {
//     img {
//       transition: all 0.5s ease-out;
//     }
//   }
// }

.fill-hover-purple {
  fill: $primary;
  transition: all 0.2s;
  &:hover,
  &:focus {
    fill: $purple;
  }
}

.fill-gold {
  fill: $gold;
}

.fill-blue {
  fill: $primary;
}

// .btn-link {
//   color: $ia-dkblue;

//   & button {
//     text-decoration: none;
//   }
// }

// Mobile Hamburger Nav
.hamburger-trigger {
  &[aria-expanded='true'] {
    .bar1 {
      -webkit-transform: rotate(-45deg) translate(-9px, 6px);
      transform: rotate(-45deg) translate(-9px, 6px);
    }

    .bar2 {
      opacity: 0;
    }

    .bar3 {
      -webkit-transform: rotate(45deg) translate(-7px, -4px);
      transform: rotate(45deg) translate(-7px, -4px);
    }
  }
}

.hamburger {
  .bar1,
  .bar2,
  .bar3 {
    width: 30px;
    height: 3px;
    margin: 6px 0;
    transition: 0.4s;
    background-color: $ia-dkblue;
  }
}

// Bootstrap 4 catch-all hold-over from sr-only being sunset
.sr-only {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

/* CSM-18: High Contrast Mode Chase Logo */
.logo-default {
  display: inline-block;
}

.logo-hcontrast {
  display: none;
}

.logo-bwcontrast {
  display: none;
}

@media (-ms-high-contrast: active) {
  .logo-default {
    display: none;
  }

  .logo-hcontrast {
    display: inline-block;
  }

  .logo-bwcontrast {
    display: none;
  }
}

@media (-ms-high-contrast: black-on-white) {
  .logo-default {
    display: none;
  }

  .logo-hcontrast {
    display: none;
  }

  .logo-bwcontrast {
    display: inline-block;
  }
}

/* CSM-19: Skip to Main Content ADA */
#skiplink {
  position: absolute;
  display: block;
  color: $white;
  font-weight: bold;
  background: $primary;
  top: -40px;
  padding: 5px 10px;
  left: 50%;
  margin-left: -82px;
  -webkit-transition: top 0.5s ease-in-out;
  -moz-transition: top 0.5s ease-in-out;
  -o-transition: top 0.5s ease-in-out;
  transition: top 0.5s ease-in-out;

  &:focus {
    top: 0;
    z-index: 56;
  }
}
#skipToEventDetails {
  position: absolute;
  display: block;
  color: $white;
  font-weight: bold;
  background: $primary;
  top: 85px;
  padding: 5px 10px;
  left: 50%;
  margin-left: -82px;
  z-index: -1;
  -webkit-transition: top 0.5s ease-in-out;
  -moz-transition: top 0.5s ease-in-out;
  -o-transition: top 0.5s ease-in-out;
  transition: top 0.5s ease-in-out;
  &:focus {
    top: 140px;
  }
  @media (max-width: 992px) {
    top: 75px;
    &:focus {
      top: 120px;
    }
  }
}

// Zoom in on tiles when hovered (WCAG no motion below)
.animate-trigger {
  .animate-zoom {
    transform: scale(1);
    transition: all 0.5s ease-out;
    -webkit-transition: all 0.5s ease-out;
    -moz-transition: all 0.5s ease-out;
  }

  &:hover,
  &:focus {
    .animate-zoom {
      -moz-transform: scale(1.1);
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }
  }
}

// Remove animation if user has "reduced motion" enabled
@media (prefers-reduced-motion) {
  .animate-trigger {
    .animate-zoom {
      transform: scale(1);
    }

    &:hover,
    &:focus {
      .animate-zoom {
        transform: scale(1);
      }
    }
  }
}

/* Custom for buttons that don't underline on hover to remain ADA compliant */
// .hover-underline {
//   &:hover,
//   &:focus {
//     text-decoration: underline !important;
//   }
// }

/* Umbraco extensions for RTF h1/h2/h3 headings inside of event details */

.event-details-container {
  h3 {
    font-size: 1.5rem;
  }
}
